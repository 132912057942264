import React from 'react';
import Box from '@mui/material/Box';
// import { useEffect } from 'react';
// import { useParams } from 'react-router';
import AppBar from '../components/AppBar';
import { Copyright } from '../components/Copyright';
import { EventFormContextProvider } from '../contexts/event/EventFormContext';
import { FeijaoComBlues } from './FeijaoComBlues';

//interface EventPageProps {
//children: React.ReactNode;
//}

export const EventPage = () => {
  // const params = useParams();

  // useEffect(() => {
  //   console.log({ ...params });
  // }, [params]);

  return (
    <EventFormContextProvider>
      <>
        <AppBar />
        <Box
          sx={{
            p: { xs: 2, md: 5, lg: 10 },
            py: { xs: 2, md: 3, lg: 4 },
          }}
        >
          <FeijaoComBlues />

          <Copyright />
        </Box>
      </>
    </EventFormContextProvider>
  );
};
