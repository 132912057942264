import { Link, Typography, TypographyProps } from '@mui/material';
import pkg from '../../package.json';

export function Copyright(props: TypographyProps) {
  return (
    <Typography
      variant="body2"
      m={2}
      fontSize="0.7rem"
      color="text.secondary"
      align="left"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://sonzera.rocks">
        Sonzera.rocks
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
      <br />
      {`v${pkg.version}`}
    </Typography>
  );
}
