// import { AccountCircle } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export const UsernameTextField = ({ ...props }: TextFieldProps) => {
  return (
    <TextField
      label={props?.label || 'Login'}
      // InputProps={{
      //   startAdornment: (
      //     <InputAdornment position="start">
      //       <AccountCircle />
      //     </InputAdornment>
      //   ),
      // }}
      {...props}
    />
  );
};
