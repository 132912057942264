import { useEffect, useState } from 'react';
import Container from '@mui/material/Container/Container';

import logo from '../styles/logo-01-480-178.png';

import '../styles/home.scss';
import { toggleCaptchaBadge } from '../utils';

export const Home = () => {
  const [opacity, setOpacity] = useState(1);
  const [scale, setScale] = useState(1);

  const hide = false;

  useEffect(() => {
    document.body.addEventListener('scroll', handleScroll);
    toggleCaptchaBadge(false);

    return () => {
      document.body.removeEventListener('scroll', handleScroll);
      toggleCaptchaBadge(true);
    };
  }, []);

  const handleScroll = () => {
    const opacity =
      (document.body.offsetHeight - document.body.scrollTop) /
      document.body.offsetHeight;
    let scale =
      (document.body.offsetHeight - document.body.scrollTop) /
      document.body.offsetHeight;
    scale = Math.max(scale, 0.5);
    setOpacity(opacity);
    setScale(scale);
  };

  return hide ? null : (
    <>
      <header className="video-header">
        <video
          src="/images/live-concert.mp4"
          autoPlay={true}
          loop
          playsInline
          muted
        ></video>
        <div
          className="viewport-header"
          style={{
            opacity: opacity,
            transform: `scale(${scale})`,
          }}
        >
          <h1>
            Em breve
            <br />
            <img
              src={logo}
              width="480"
              height="178"
              id="logo"
              alt="Sonzera.Rocks Eventos Musicais"
            />
          </h1>
        </div>
      </header>

      <main>
        <Container
          sx={{
            height: '100vh',
            bgcolor: '#00000000',
          }}
        ></Container>

        {/* <Popper open={true} anchorEl={mainRef.current} placement="bottom" transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Typography sx={{ p: 2 }}>
                  <p>Política de utilização de Cookies:</p>
                  <p>Para proporcionar melhor experiência e navegabilidade, este site utiliza cookies. </p>
                </Typography>
              </Paper>
            </Fade>
          )}
        </Popper> */}
      </main>

      <div id="circle"></div>
    </>
  );
};
