import { useCallback, useState } from 'react';

import { doc, setDoc } from 'firebase/firestore';

import { ContentCopy, MapOutlined } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog/';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Collapse from '@mui/material/Collapse';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  EnrollmentForm,
  EventSummaryForm,
  PaymentForm,
  useEventFormContext,
} from '../contexts/event/EventFormContext';

import { store } from '../services/firebase';
import { QrCodePix } from '../utils/pix';
import { getUserAgent } from '../utils';

export const FeijaoComBlues = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showAddressActions, setShowAddressActions] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const {
    showToast,
    form,
    validateForm,
    toggleErrors,
    showPayment,
    setShowPayment,
    total,
    setPixPayload,
  } = useEventFormContext();

  const isEventClosed = true;

  const handleSubmit = useCallback(async () => {
    toggleErrors(true);

    const { count } = await validateForm(form);
    if (count) return;

    if (!executeRecaptcha) {
      // console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('FormSubmit');
    // Do whatever you want with the token

    if (token) {
      // console.log('handleReCaptchaVerify: token returned. Form can be submitted.', token);
      setShowPayment(true);

      const transactionDate = new Date();
      const transactionId = transactionDate.toISOString().replace(/\D/g, '');

      const pixSettings = {
        version: '01',
        key: '43044077000176',
        name: 'Jazz Sweets CWB',
        city: 'CURITIBA',
        transactionId: transactionId,
        message: 'Festival Feijao com Blues',
        cep: '82410150',
        value: total,
      };

      const qrCodePix = QrCodePix(pixSettings);
      const payload = qrCodePix.payload();

      const transactionData = {
        transactionId,
        total,
        formData: {
          ...form,
          companion: form.hasCompanion
            ? form.companion.filter(row => !!row.name && !!row.age)
            : [],
        },
        pixPayload: payload,
        createdAt: transactionDate.toISOString(),
        userAgent: getUserAgent(),
      };
      // console.log('Full transaction', transactionData);

      try {
        await setDoc(
          doc(store, 'transactions', transactionId),
          transactionData,
        );
        setPixPayload(payload);
      } catch (err) {
        console.log('Error sending transaction to store.');
      }
    }
  }, [
    form,
    total,
    executeRecaptcha,
    setPixPayload,
    setShowPayment,
    toggleErrors,
    validateForm,
  ]);

  return (
    <>
      <Typography variant="h4" component="p">
        <b>Ingressos</b>
      </Typography>

      <Grid container spacing={1} mb={2} mt={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="p">
            <b>Evento</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md="auto">
          <Typography variant="h5" component="p">
            Festival Feijão com Blues
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} m={1} alignItems="flex-start">
        <Grid item xs={12} md={4}>
          <Card
            elevation={6}
            sx={{ maxWidth: 320 }}
            onClick={() => setDialogOpen(true)}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                width="100%"
                image="/images/events/flyer-feijao-com-blues-01.png"
                alt="Festival Feijão com Blues"
              />
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={1} columns={8}>
            <Grid item xs={3} fontSize="0.9em">
              <b>Descrição</b>
            </Grid>
            <Grid item xs={5} fontSize="0.9em">
              1ª Edição do Festival Feijão com Blues
            </Grid>

            <Grid item xs={3} fontSize="0.9em">
              <b>Data</b>
            </Grid>
            <Grid item xs={5} fontSize="0.9em">
              19 de Março de 2022
            </Grid>

            <Grid item xs={3} fontSize="0.9em">
              <b>Abertura</b>
            </Grid>
            <Grid item xs={5} fontSize="0.9em">
              11:30
            </Grid>

            <Grid item xs={3} fontSize="0.9em">
              <b>Encerramento</b>
            </Grid>
            <Grid item xs={5} fontSize="0.9em">
              20:00
            </Grid>

            <Grid item xs={3} fontSize="0.9em">
              <b>Almoço</b>
            </Grid>
            <Grid item xs={5} fontSize="0.9em">
              Das 11:30 às 14:30
            </Grid>

            <Grid item xs={3} fontSize="0.9em">
              <b>Endereço</b>
            </Grid>
            <Grid
              item
              xs={5}
              fontSize="0.9em"
              onMouseOver={() => setShowAddressActions(true)}
            >
              Umbará EcoParque
              <br />
              Rua Nicola Pellanda, 6802
              <br />
              Umbará - Curitiba - PR
              <br />
              {showAddressActions && (
                <>
                  <Button
                    variant="text"
                    size="small"
                    sx={{ mr: 2 }}
                    endIcon={<ContentCopy />}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        'Rua Nicola Pellanda, 6802, Umbará, Curitiba - PR',
                      );
                      showToast('Endereço copiado com sucesso!');
                    }}
                  >
                    Copiar
                  </Button>

                  <Button
                    variant="text"
                    size="small"
                    endIcon={<MapOutlined />}
                    href="https://goo.gl/maps/SiPXJWZGMXmPbRMr9"
                    target="_blank"
                  >
                    Mapa
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        {!showPayment && (
          <Grid item xs={12} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} fontSize="0.9em">
                <b>Ingressos</b>
              </Grid>
              <Grid item xs={12} fontSize="0.9em">
                De 0 a 3 anos: Gratuito
              </Grid>
              <Grid item xs={12} fontSize="0.9em">
                De 3 a 10 anos: R$ 25,00
              </Grid>
              <Grid item xs={12} fontSize="0.9em">
                Maiores de 10 anos: R$ 40,00
              </Grid>

              <Grid item xs={12} fontSize="0.9em" mt={2}>
                <b>Estacionamento</b>
              </Grid>
              <Grid item xs={12} fontSize="0.9em">
                Motos: Gratuito
              </Grid>
              <Grid item xs={12} fontSize="0.9em">
                Carros: R$ 10,00 em local ao lado do evento (particular).
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Typography variant="body2" component="p" sx={{ m: 2 }}>
        Os ingressos serão distribuídos em forma de pulseiras no dia do evento,
        para aqueles cujos pagamentos do Pix tenham sido recebidos.
      </Typography>

      {/* <form id="snzForm" onSubmit={e => e.preventDefault()}> */}

      {isEventClosed && (
        <Paper
          elevation={6}
          sx={{
            p: { xs: 1, md: 3 },
          }}
        >
          <h3>Venda online de ingressos encerrada.</h3>
        </Paper>
      )}
      {!isEventClosed && (
        <>
          <Collapse in={!showPayment}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={8}>
                <Paper
                  elevation={6}
                  sx={{
                    p: { xs: 1, md: 3 },
                  }}
                >
                  <EnrollmentForm />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper
                  elevation={6}
                  sx={{
                    p: { xs: 1 },
                  }}
                >
                  <EventSummaryForm submitHandler={handleSubmit} />
                </Paper>
              </Grid>
            </Grid>
          </Collapse>

          <Collapse in={showPayment}>
            <Paper
              elevation={6}
              sx={{
                p: { xs: 1, md: 3 },
              }}
            >
              <PaymentForm />
            </Paper>
          </Collapse>

          <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={isDialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <DialogContent>
              <img
                src="/images/events/flyer-feijao-com-blues-01.png"
                alt="Festival Feijão com Blues"
                width="100%"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)}>Fechar</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};
