import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    summaryTitle: React.CSSProperties;
    summary: React.CSSProperties;
    observation: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    summaryTitle?: React.CSSProperties;
    summary?: React.CSSProperties;
    observation?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    summaryTitle: true;
    summary: true;
    observation: true;
  }
}

export const theme = createTheme({
  typography: {
    summaryTitle: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
    },
    summary: {
      fontSize: '0.8rem',
    },
    observation: {
      fontSize: '0.8rem',
      color: 'grey',
    },
  },
  palette: {
    mode: 'light',
    background: {
      default: '#efefef',
    },
    primary: {
      main: '#3e73c5',
    },
    secondary: {
      main: '#ff3d00',
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
      },
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#1a1f26',
          color: '#fff',
        },
      },
    },
  },
});
