/* eslint-disable no-useless-escape */

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (value: string) => {
  return new RegExp(emailRegex).test(value);
};

export const toggleCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName('grecaptcha-badge')[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? 'visible' : 'hidden';
  } else if (!show) {
    setTimeout(() => toggleCaptchaBadge(show), 1000);
  }
};

export const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const getUserAgent = () => {
  if (window && window.navigator && window.navigator.userAgent)
    return window.navigator.userAgent;
  return null;
};
