import { useState } from 'react';

// import { signInWithEmailAndPassword } from 'firebase/auth';
// import { auth } from '../../services/firebase';

import { Copyright } from '../../components/Copyright';

import AppBar from '../../components/AppBar';
import Box from '@mui/material/Box/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { UsernameTextField } from '../../components/UsernameTextField';
import { PasswordTextField } from '../../components/PasswordTextField';

export const AdminPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // useEffect(() => { });

  // const handleClick = () => {
  //   signInWithEmailAndPassword(auth, email, password)
  //     .then(userCredential => {
  //       // Signed in
  //       const user = userCredential.user;
  //       // ...
  //     })
  //     .catch(error => {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //     });
  // };

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <AppBar />
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <Paper
          component={Container}
          elevation={16}
          sx={{
            width: 'unset',
            margin: 2,
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          }}
        >
          <Grid container spacing={0} mx={0} my={0}>
            <Grid item xs={12} md={6}>
              <Box mx={4} my={6}>
                <Grid container spacing={0} mx={0} my={0}>
                  <Grid item xs={12} pb={4}>
                    <Typography variant="h5">Login</Typography>
                  </Grid>

                  <Grid item xs={12} pb={2}>
                    <UsernameTextField
                      size="small"
                      fullWidth
                      label="E-mail"
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} pb={2}>
                    <PasswordTextField
                      size="small"
                      fullWidth
                      label="Senha"
                      value={password}
                      onChange={event => setPassword(event.target.value)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    mt={3}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button variant="contained">Entrar</Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} display="flex" flexDirection="column">
              <Box
                flex={1}
                p={4}
                sx={{
                  backgroundColor: 'primary.main',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                }}
              ></Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Copyright />
    </Box>
  );
};
