// import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
// import MoreIcon from '@mui/icons-material/MoreVert';

export default function ProminentAppBar() {
  return (
    <Box sx={{ displayPrint: 'none' }}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}

          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Sonzera.rocks
          </Typography>

          {/* <IconButton size="large" aria-label="search" color="inherit">
            <SearchIcon />
          </IconButton>

          <IconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
          >
            <MoreIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
