import { initializeApp } from 'firebase/app';

import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
};

const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcnryUeAAAAADxFJDORkS7vFM21CqolmfnravbB'),
  isTokenAutoRefreshEnabled: true,
});

const analytics = getAnalytics(app);

const auth = getAuth(app);
const database = getDatabase(app);
const store = getFirestore(app);

export { auth, database, store, analytics, appCheck };
