import React from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { Route } from 'react-router';

import { Home } from './pages/Home';
import { EventPage } from './pages/Event';
import { AdminPage } from './pages/Admin';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/evento/:eventId" element={<EventPage />} />
          <Route path="/admin" element={<AdminPage />} />

          <Route path="/FeijaoComBlues" element={<EventPage />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
