import React, { ReactNode, useContext, useEffect, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { EnrollmentForm } from './EnrollmentForm';
import { EventDetailedSummary } from './EventDetailedSummary';
import { EventSummary } from './EventSummary';
import { EventSummaryForm } from './EventSummaryForm';
import { PaymentForm } from './PaymentForm';

import { FormType, FormErrors, Person, AgeType, PersonError } from './index';
import { validateEmail } from '../../utils';

interface EventFormContextType {
  form: FormType;
  // updateForm: (form: FormType) => void,
  updateName: (name: string) => void;
  updateEmail: (email: string) => void;
  updatePhone: (phone: string) => void;
  updateHasCompanion: (has: boolean) => void;
  updateTotalCompanion: (total: number) => void;
  updateCompanionName: (row: number, name: string) => void;
  updateCompanionAge: (row: number, age: AgeType) => void;
  errors: FormErrors;
  //updateErrors: (errors: FormErrors) => void,
  validateForm: (data: FormType) => Promise<FormErrors>;
  showErrors: boolean;
  toggleErrors: (show: boolean) => void;
  // setShowErrors: (show: boolean) => void,
  pricing: Map<AgeType, number>;
  total: number;
  showPayment: boolean;
  setShowPayment: (show: boolean) => void;
  pixPayload: string | null;
  setPixPayload: (payload: string) => void;
  showToast: (text: string) => void;
  hideToast: () => void;
}

type EventFormContextProps = {
  children: ReactNode;
};

const defaultCompanionSet = Array<Person>(10);
const defaultCompanionErrorSet = Array<PersonError>(10);
for (let i = 0; i < 10; i++) {
  defaultCompanionSet[i] = {
    name: '',
    age: '',
  };
  defaultCompanionErrorSet[i] = {
    name: '',
    age: '',
  };
}

const Prices: Map<AgeType, number> = new Map([
  ['default', 40],
  ['0-3', 0],
  ['3-10', 25],
  ['10-n', 40],
]);

export const EventFormContext = React.createContext({} as EventFormContextType);

export const EventFormContextProvider = ({
  children,
}: EventFormContextProps) => {
  const [form, setForm] = useState<FormType>({
    name: '',
    email: '',
    phone: '',
    hasCompanion: false,
    companion: defaultCompanionSet,
    totalCompanion: 0,
  });

  const [showSnack, setShowSnack] = useState(false);
  const [snackText, setSnackText] = useState('');

  const showToast = (text: string) => {
    setSnackText(text);
    setShowSnack(true);
  };
  const hideToast = () => {
    setShowSnack(false);
  };

  const [errors, setErrors] = useState<FormErrors>({} as FormErrors);
  const [showErrors, setShowErrors] = useState(false);

  const [total, setTotal] = useState<number>(0);

  const [showPayment, setShowPayment] = useState(false);
  const [pixPayload, setPixPayload] = useState<string | null>(null);

  const updateName = async (value: string) => {
    setForm({ ...form, name: value });
  };
  const updateEmail = async (value: string) => {
    setForm({ ...form, email: value });
  };
  const updatePhone = (value: string) => {
    setForm({ ...form, phone: value });
  };

  const updateHasCompanion = async (has: boolean) => {
    const total = !has ? 0 : form.totalCompanion;
    setForm({
      ...form,
      hasCompanion: has,
      totalCompanion: has && total === 0 ? 1 : total,
    });
  };

  const updateTotalCompanion = async (value: number) => {
    if (value > 0 && value <= 10) {
      setForm({ ...form, totalCompanion: value });
    }
  };

  const updateCompanionName = (row: number, name: string) => {
    const newData: Person[] = [...form.companion];
    newData[row].name = name;
    setForm({ ...form, companion: newData });
  };

  const updateCompanionAge = (row: number, value: AgeType) => {
    const newData: Person[] = [...form.companion];
    newData[row].age = value;
    setForm({ ...form, companion: newData });
  };

  const validateForm = async (data: FormType) => {
    const validation: FormErrors = {
      companion: [],
      count: 0,
    };

    if (!data.name.trim()) {
      validation.name = '* Obrigatório';
      validation.count++;
    } else if (!data.name.trim().includes(' ')) {
      validation.name = 'O nome precisa estar completo';
      validation.count++;
    } else if (data.name.trim().length < 6) {
      validation.name = 'Necessário ter mais de 5 caracteres';
      validation.count++;
    }

    if (!data.email.trim()) {
      validation.email = '* Obrigatório';
      validation.count++;
    } else if (!validateEmail(data.email)) {
      validation.email = 'E-mail inválido';
      validation.count++;
    }

    const reg = new RegExp(/\([0-9]{2}\) [0-9]{5}-[0-9]{4}/);
    if (!data.phone.trim() || data.phone === '(  )      -     ') {
      validation.phone = '* Obrigatório';
      validation.count++;
    } else if (!reg.test(data.phone)) {
      validation.phone = 'Valor inválido';
      validation.count++;
    }

    validation.companion = [];
    if (data.hasCompanion) {
      for (let i = 0; i < data.totalCompanion; i++) {
        validation.companion[i] = {};

        if (!data.companion[i].name.trim()) {
          validation.companion[i].name = '* Obrigatório';
          validation.count++;
        } else if (data.companion[i].name.trim().length < 4) {
          validation.companion[i].name = 'Necessário ter mais de 3 caracteres';
          validation.count++;
        }

        if (!data.companion[i].age.trim()) {
          validation.companion[i].age = '* Obrigatório';
          validation.count++;
        }
      }
    }

    setErrors(validation);
    return validation;
  };

  useEffect(() => {
    if (!showErrors) return;

    // Resets showErrors flag if it's all good now
    async function validate() {
      const { count } = await validateForm(form);
      if (!count) setShowErrors(false);
    }
    validate();
  }, [form, showErrors, setShowErrors]);

  useEffect(() => {
    let newTotal = 0.0;

    newTotal += Prices.get('default') || 0;

    if (form.hasCompanion) {
      newTotal += form.companion.reduce<number>((acc, row, idx) => {
        if (idx >= form.totalCompanion) return acc;
        if (!row || !row.age) return acc;
        return acc + (row.age ? Prices.get(row.age) || 0 : 0);
      }, 0);
    }

    setTotal(newTotal);
  }, [form]);

  return (
    <EventFormContext.Provider
      value={{
        form,
        updateName,
        updateEmail,
        updatePhone,
        updateHasCompanion,
        updateTotalCompanion,
        updateCompanionName,
        updateCompanionAge,
        validateForm,
        errors,
        showErrors,
        showPayment,
        setShowPayment,
        toggleErrors: (show: boolean) => {
          setShowErrors(show);
        },
        pricing: Prices,
        total,
        pixPayload,
        setPixPayload,
        showToast,
        hideToast,
      }}
    >
      <>
        {children}
        <Snackbar open={showSnack} autoHideDuration={5000} onClose={hideToast}>
          <Alert severity="info">{snackText}</Alert>
        </Snackbar>
      </>
    </EventFormContext.Provider>
  );
};

export const useEventFormContext = () => {
  const context = useContext(EventFormContext);
  return context;
};

export {
  EnrollmentForm,
  EventDetailedSummary,
  EventSummary,
  EventSummaryForm,
  PaymentForm,
};
